
export default function useLoadingHandler() {
    const toggleLoading = (loadingId: string, display: boolean) => {
        let loadingContainer = document.getElementById(loadingId);
        if (loadingContainer != null) {
            if (display) {
                loadingContainer.style.display = "block";
            } else {
                loadingContainer.style.display = "none";
            }

        }
    }

    return {
        toggleLoading
    }
}