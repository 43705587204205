<template>
  <Carousel class="attribute-slider__parent">

    <Slide class="attribute-slider__parent-slide" v-for="(slide, indx) in attributes.slider" :key="`parent${indx}`">
      <h2 class="dg-title dg-title--h2 dg-title--center dg-title--underline dg-title-h2-responsive ptb-20">
        <span>{{ slide.title }}</span>
      </h2>
      <!-- First slider Carousel -->
      <Carousel class="attribute-slider__child">
        <Slide class="attribute-slider__child-slide" v-for="(childEl, index) in slide.child_slider"
          :key="childEl.title + index">
          <div class="carousel__item">
            <img loading="lazy" :src="childEl.img"
              :alt="'Dogggo FUD attribute: ' + slide.title + ' - ' + childEl.title" />
          </div>
          <h2 class="dg-title dg-title--h2 dg-title--center dg-title--underline dg-title-h2-responsive ptb-20">
            <span>{{ childEl.title }}</span>
          </h2>
        </Slide>

        <!--  Arrows and Dots/Pagination -->
        <template #addons>
          <Navigation />
          <!-- <Pagination /> -->
        </template>
      </Carousel>
    </Slide>

    <!--  Arrows and Dots/Pagination -->
    <template #addons>
      <Navigation />
      <!-- <Pagination /> -->
    </template>
  </Carousel>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';

export default defineComponent({
  name: 'AttributeSlider',
  components: {
    Carousel,
    Slide,
    Navigation,
    // Pagination,
  },
  data() {
    return {
      attributes: {
        slider: [
          {
            title: "Background",
            child_slider: [
              {
                title: "Blue",
                img: "../assets/carrousel/background/BLUE.jpg"
              },
              {
                title: "Gray",
                img: "../assets/carrousel/background/GRAY.jpg"
              },
              {
                title: "Green",
                img: "../assets/carrousel/background/GREEN.jpg"
              },
              {
                title: "Pink",
                img: "../assets/carrousel/background/PINK.jpg"
              },
              {
                title: "White",
                img: "../assets/carrousel/background/WHITE.jpg"
              },
              {
                title: "Yellow",
                img: "../assets/carrousel/background/YELLOW.jpg"
              }
            ]
          },
          {
            title: "Body",
            child_slider: [
              {
                title: "Acupuncture",
                img: "../assets/carrousel/body/ACUPUNCTURE.jpg"
              },
              {
                title: "Bib",
                img: "../assets/carrousel/body/BIB.jpg"
              },
              {
                title: "Bullets",
                img: "../assets/carrousel/body/BULLETS.jpg"
              },
              {
                title: "Caparison",
                img: "../assets/carrousel/body/CAPARISON.jpg"
              },
              {
                title: "Empty",
                img: "../assets/carrousel/body/EMPTY.jpg"
              },
              {
                title: "Hulahoop",
                img: "../assets/carrousel/body/HULAHOOP.jpg"
              },
              {
                title: "Jacket",
                img: "../assets/carrousel/body/JACKET.jpg"
              },
              {
                title: "Leaf",
                img: "../assets/carrousel/body/FICUS.jpg"
              },
              {
                title: "Lifebelt",
                img: "../assets/carrousel/body/LIFEBELT.jpg"
              },
              {
                title: "Pattern",
                img: "../assets/carrousel/body/PATTERN.jpg"
              },
              {
                title: "Rocket",
                img: "../assets/carrousel/body/ROCKET.jpg"
              },
              {
                title: "Sexy",
                img: "../assets/carrousel/body/SEXY.jpg"
              },
              {
                title: "Shark",
                img: "../assets/carrousel/body/SHARK.jpg"
              },
              {
                title: "Sheriff",
                img: "../assets/carrousel/body/SHERIFF.jpg"
              },
              {
                title: "Slaughter",
                img: "../assets/carrousel/body/Slaughter.jpg"
              },
              {
                title: "Spatialism",
                img: "../assets/carrousel/body/PAINTING.jpg"
              },
              {
                title: "Tattoo",
                img: "../assets/carrousel/body/TATTOO.jpg"
              },
              {
                title: "Tutu",
                img: "../assets/carrousel/body/TUTU.jpg"
              },
              {
                title: "Wings",
                img: "../assets/carrousel/body/WINGS.jpg"
              },
              {
                title: "Xrays",
                img: "../assets/carrousel/body/BONE.jpg"
              },
            ]
          },
          {
            title: "Eyes",
            child_slider: [
              {
                title: "Babe",
                img: "../assets/carrousel/eyes/BABE.jpg"
              },
              {
                title: "Cartoon",
                img: "../assets/carrousel/eyes/CARTOON.jpg"
              },
              {
                title: "Empty",
                img: "../assets/carrousel/eyes/EMPTY.jpg"
              },
              {
                title: "Fomo",
                img: "../assets/carrousel/eyes/Fomo.jpg"
              },
              {
                title: "Laser",
                img: "../assets/carrousel/eyes/LASER.jpg"
              },
              {
                title: "Lashes",
                img: "../assets/carrousel/eyes/LASHES.jpg"
              },
              {
                title: "Love",
                img: "../assets/carrousel/eyes/LOVE.jpg"
              },
              {
                title: "Mask",
                img: "../assets/carrousel/eyes/MASK.jpg"
              },
              {
                title: "Nerd",
                img: "../assets/carrousel/eyes/Nerd.jpg"
              },
              {
                title: "Opera",
                img: "../assets/carrousel/eyes/OPERA.jpg"
              },
              {
                title: "Outside",
                img: "../assets/carrousel/eyes/OUTSIDE.jpg"
              },
              {
                title: "Pantera",
                img: "../assets/carrousel/eyes/PANTERA.jpg"
              },
              {
                title: "Pirate",
                img: "../assets/carrousel/eyes/PIRATE.jpg"
              },
              {
                title: "Psyco",
                img: "../assets/carrousel/eyes/PSYCO.jpg"
              },
              {
                title: "Spiral",
                img: "../assets/carrousel/eyes/SPIRAL.jpg"
              },
              {
                title: "Springs",
                img: "../assets/carrousel/eyes/SPRING.jpg"
              },
              {
                title: "Star",
                img: "../assets/carrousel/eyes/STAR.jpg"
              },
              {
                title: "Sunglasses",
                img: "../assets/carrousel/eyes/SUNGLASSES.jpg"
              },
              {
                title: "Thuglife",
                img: "../assets/carrousel/eyes/THUGLIFE.jpg"
              },
              {
                title: "X",
                img: "../assets/carrousel/eyes/X.jpg"
              }
            ]
          },
          {
            title: "Head",
            child_slider: [
              {
                title: "Apache",
                img: "../assets/carrousel/head/APACHE.jpg"
              },
              {
                title: "Bandana",
                img: "../assets/carrousel/head/Bandana.jpg"
              },
              {
                title: "Brain",
                img: "../assets/carrousel/head/BRAIN.jpg"
              },
              {
                title: "Cap",
                img: "../assets/carrousel/head/Cap.jpg"
              },
              {
                title: "Chef",
                img: "../assets/carrousel/head/CHEF.jpg"
              },
              {
                title: "Clown",
                img: "../assets/carrousel/head/CLOWN.jpg"
              },
              {
                title: "Cop",
                img: "../assets/carrousel/head/COP.jpg"
              },
              {
                title: "Coppola",
                img: "../assets/carrousel/head/Coppola.jpg"
              },
              {
                title: "Crown",
                img: "../assets/carrousel/head/KING.jpg"
              },
              {
                title: "Diamonds",
                img: "../assets/carrousel/head/DIAMONDS.jpg"
              },
              {
                title: "Empty",
                img: "../assets/carrousel/head/EMPTY.jpg"
              },
              {
                title: "FMJ",
                img: "../assets/carrousel/head/FMJ.jpg"
              },
              {
                title: "Football",
                img: "../assets/carrousel/head/FOOTBALL.jpg"
              },
              {
                title: "Headphones",
                img: "../assets/carrousel/head/HEADPHONE.jpg"
              },
              {
                title: "Military",
                img: "../assets/carrousel/head/Military.jpg"
              },
              {
                title: "Praetorian",
                img: "../assets/carrousel/head/PRAETORIAN.jpg"
              },
              {
                title: "Queen",
                img: "../assets/carrousel/head/Queen.jpg"
              },
              {
                title: "Rasta",
                img: "../assets/carrousel/head/RASTA.jpg"
              },
              {
                title: "Tophat",
                img: "../assets/carrousel/head/Tophat.jpg"
              },
              {
                title: "Wig",
                img: "../assets/carrousel/head/WIG.jpg"
              }
            ]
          },
          {
            title: "Snout",
            child_slider: [
              {
                title: "Angry",
                img: "../assets/carrousel/snout/ANGRY.jpg"
              },
              {
                title: "Bubble",
                img: "../assets/carrousel/snout/BUBBLE.jpg"
              },
              {
                title: "Doge",
                img: "../assets/carrousel/snout/DOGE.jpg"
              },
              {
                title: "Dummy",
                img: "../assets/carrousel/snout/DUMMY.jpg"
              },
              {
                title: "Empty",
                img: "../assets/carrousel/snout/EMPTY.jpg"
              },
              {
                title: "Gold",
                img: "../assets/carrousel/snout/GOLD.jpg"
              },
              {
                title: "Growl",
                img: "../assets/carrousel/snout/GROWL.jpg"
              },
              {
                title: "Joint",
                img: "../assets/carrousel/snout/Joint.jpg"
              },
              {
                title: "Mandible",
                img: "../assets/carrousel/snout/MANDIBLE.jpg"
              },
              {
                title: "Muzzle",
                img: "../assets/carrousel/snout/MUZZLE.jpg"
              },
              {
                title: "Rabbit",
                img: "../assets/carrousel/snout/RABBIT.jpg"
              },
              {
                title: "Scream",
                img: "../assets/carrousel/snout/SCREAM.jpg"
              },
              {
                title: "Slime",
                img: "../assets/carrousel/snout/SLIME.jpg"
              },
              {
                title: "Smile",
                img: "../assets/carrousel/snout/SMILE.jpg"
              },
              {
                title: "Snort",
                img: "../assets/carrousel/snout/SNORT.jpg"
              },
              {
                title: "Spaghetti",
                img: "../assets/carrousel/snout/SPAGHETTI.jpg"
              },
              {
                title: "Teeth",
                img: "../assets/carrousel/snout/TEETH.jpg"
              },
              {
                title: "Tongue",
                img: "../assets/carrousel/snout/TONGUE.jpg"
              },
              {
                title: "Trip",
                img: "../assets/carrousel/snout/TRIP.jpg"
              }
            ]
          },
          {
            title: "Items",
            child_slider: [
              {
                title: "Bass",
                img: "../assets/carrousel/items/BASS.jpg"
              },
              {
                title: "Trumpet",
                img: "../assets/carrousel/items/BEPBEP.jpg"
              },
              {
                title: "Empty",
                img: "../assets/carrousel/items/EMPTY.jpg"
              },
              {
                title: "Money",
                img: "../assets/carrousel/items/EURO.jpg"
              },
              {
                title: "Gun",
                img: "../assets/carrousel/items/GUN.jpg"
              },
              {
                title: "Hifi",
                img: "../assets/carrousel/items/HIFI.jpg"
              },
              {
                title: "Katana",
                img: "../assets/carrousel/items/KATANA.jpg"
              },
              {
                title: "Lupara",
                img: "../assets/carrousel/items/Lupara.jpg"
              },
              {
                title: "Molotov",
                img: "../assets/carrousel/items/Molotov.jpg"
              },
              {
                title: "Necklace",
                img: "../assets/carrousel/items/NECKLACE.jpg"
              },
              {
                title: "Nunchaku",
                img: "../assets/carrousel/items/NUNCHAKU.jpg"
              },
              {
                title: "Rose",
                img: "../assets/carrousel/items/ROSE.jpg"
              },
              {
                title: "Skates",
                img: "../assets/carrousel/items/ROLLER.jpg"
              },
              {
                title: "Skateboard",
                img: "../assets/carrousel/items/SKATE.jpg"
              },
              {
                title: "Socks",
                img: "../assets/carrousel/items/SOCKS.jpg"
              },
              {
                title: "Spray",
                img: "../assets/carrousel/items/Spray.jpg"
              },
              {
                title: "Switchblade",
                img: "../assets/carrousel/items/SWITCHBLADE.jpg"
              },
              {
                title: "Vodka",
                img: "../assets/carrousel/items/VODKA.jpg"
              },
              {
                title: "Wheels",
                img: "../assets/carrousel/items/WHEELS.jpg"
              },
              {
                title: "Watergun",
                img: "../assets/carrousel/items/WATERGUN.jpg"
              }
            ]
          }
        ]
      }
    };
  },

});
</script>

<style src="vue3-carousel/dist/carousel.css">
</style>

<style scoped>
img {
  width: 100%;
}

.carousel__item {
  min-height: 200px;
  color: #000;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  transform: none;
}

@media only screen and (max-width: 480px) {
  .carousel__item {
    width: calc(100% - 40px);
  }
}

.carousel /deep/ .carousel__prev {
  box-sizing: content-box;
  border: 5px solid white;
  transform: none;
  border-radius: 0;
  background: url("../assets/border/border-btn-fud.png") 0 0 / 100% 100% transparent;
  width: 50px;
  height: 50px;
}

.carousel /deep/ .carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
  transform: none;
  border-radius: 0;
  background: url("../assets/border/border-btn-fud.png") 0 0 / 100% 100% transparent;
  width: 50px;
  height: 50px;
}

.carousel /deep/ .carousel__prev--in-active,
.carousel /deep/ .carousel__next--in-active {
  background: url("../assets/border/border-btn-disable.png") no-repeat 0 0 / auto 100% transparent;
  border-radius: 20px;
}

.attribute-slider__parent>/deep/ .carousel__prev,
.attribute-slider__parent>/deep/ .carousel__next {
  top: 20px;
}

.attribute-slider__child {
  width: 100%;
}

.attribute-slider__child>/deep/ .carousel__prev,
.attribute-slider__child>/deep/ .carousel__next {
  top: unset;
  bottom: 20px;
}

.carousel /deep/.carousel__icon {
  color: #000;
  font-size: 26px;
}

.attribute-slider__parent-slide,
.attribute-slider__child-slide {
  display: flex;
  flex-direction: column;
}
</style>