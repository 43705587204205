<template>

    <!--Navbutton templaes-->
    <div class="dg-nav-btn">
        <router-link to="/" aria-label="Click open the link" class="dg-button dg-button--fud">
            F.U.D
        </router-link>

        <router-link to="/mint" aria-label="Click open the link" class="dg-button dg-button--buy">
             Mint
        </router-link>

        <router-link to="/my-collection" aria-label="Click open the link" class="dg-button dg-button--show">
            Show
        </router-link>
    </div>

</template>

<script>

export default {
  name: 'NavigationComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dg-nav-btn {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    margin: 0 auto;
}


@media only screen and (min-width: 1024px) {
    .dg-nav-btn {
        padding: 40px 0;
    }
}

</style>
