import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import HomeFUDView from '../views/HomeFUDView.vue';
import useLoadingHandler from "../composables/useLoadingHandler";

const {
  toggleLoading
} = useLoadingHandler();

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeFUDView
  },
  {
    path: '/mint',
    name: 'mint',
    component: () => import(/* webpackChunkName: "mint" */ '../views/BuyView.vue')
  },
  {
    path: '/my-collection',
    name: 'my-collection',
    component: () => import(/* webpackChunkName: "mycollection" */ '../views/MyCollectionView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.afterEach((to, from) => {
  if (to.fullPath != from.fullPath) {
    toggleLoading('main-loader', true);
  }
})

export default router