<template>
  <li class="dg-accordion__item">
    <div 
      class="dg-accordion__trigger"
      :class="{'dg-accordion__trigger_active': visible}"
      @click="open">

      <!-- This slot will handle the title/header of the accordion and is the part you click on -->
      <slot name="accordion-trigger"></slot>
    </div>

    <transition 
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end">

      <div class="dg-accordion__content"
        v-show="visible">
        <slot name="accordion-content"></slot>
      </div>
    </transition>
  </li>
</template>


<script>
export default {
  props: {},
  inject: ["Accordion"],
  data() {
    return {
      index: null
    };
  },
  computed: {
    visible() {
      return this.Accordion.active.includes(this.index);
    }
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = this.Accordion.active.filter(element => element != this.index);;
      } else {
        this.Accordion.active.push(this.index);
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    }
  },
  created() {
    this.index = this.Accordion.count++;
  }
};
</script>

<style scoped>
  .dg-accordion__item {
    cursor: pointer;
    padding: 10px 20px 10px 40px;
    position: relative;
    margin-bottom: 16px;
  }

  .dg-accordion__trigger {
    font-size: 30px;
    line-height: 38px;
    color: #000;
    background: none;
    cursor: pointer;
    font-family: inherit;
  }

  .dg-accordion__trigger /deep/ .dg-accordion__item-btn {
    background: none;
    font-size: 30px;
    line-height: 38px;
    color: #000;
    font-family: inherit;
    cursor: pointer;
  }

  .dg-accordion__content {
    margin-top: 10px;
  }

  .dg-accordion__content /deep/ * {
    font-size: 30px;
    line-height: 38px;
    color: #000;
    font-family: inherit;
  }

  .accordion-enter-active,
  .accordion-leave-active {
    will-change: height, opacity;
    transition: height 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
  }

  .accordion-enter,
  .accordion-leave-to {
    height: 0 !important;
    opacity: 0;
  }
</style>
