<template>
  <ul class="dg-accordion">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      Accordion: {
        count: 0,
        active: []
      }
    };
  },
  provide() {
    return { Accordion: this.Accordion };
  }
};
</script>

<style scoped>
    .dg-accordion {
        list-style: none;
        margin: 0;
        padding: 0;
    }
</style>
