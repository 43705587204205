export const BINANCE_NETWORK_ID = process.env.VUE_APP_BINANCE_NETWORK_ID!;
export const BINANCE_NETWORK_ID_HEX = process.env.VUE_APP_BINANCE_NETWORK_ID_HEX!;

export const ETH_NETWORK_ID = process.env.VUE_APP_ETH_NETWORK_ID!;
export const IMMUTABLE_X_PUBLIC_API_URL = process.env.VUE_APP_IMMUTABLE_X_PUBLIC_API_URL!;
export const IMMUTABLE_X_LINK_URL = process.env.VUE_APP_IMMUTABLE_X_LINK_URL;
export const IMMUTABLE_X_DOGGGO_FUD_COLLECTION = process.env.VUE_APP_IMMUTABLE_X_DOGGGO_FUD_COLLECTION;

export const BINANCE_CONNECTION = {
    rpc: { [+(BINANCE_NETWORK_ID as string)]: process.env.VUE_APP_BINANCE_RPC },
};
export const DOGGGO_ADDRESS = process.env.VUE_APP_DOGGGO_ADDRESS;
export const DOGGGO_NFT_ADDRESS = process.env.VUE_APP_DOGGGO_NFT_ADDRESS;
export const ABI_PATH = "abi/";
export const DOGGGO_ABI_NAME = "Token.json";
export const DOGGGO_RESERVE_NFT_ABI_NAME = "DogggoReserveNFT.json";

export const URLS = {
    buy: `https://pancakeswap.finance/swap?outputCurrency=${DOGGGO_ADDRESS}`,
    chart: `https://poocoin.app/tokens/${DOGGGO_ADDRESS}`,
    buyGuide: "download/How-to-buy-Dogggo.pdf",
    twitter: "https://twitter.com/DogggoToken",
    medium: "https://dogggo.medium.com/",
    facebook: "https://www.facebook.com/dogggotoken",
    instagram: "https://instagram.com/dogggotoken/",
    reddit: "https://www.reddit.com/r/DogggoToken/",
    tiktok: "https://www.tiktok.com/@dogggo_trollcoin",
    telegram: "https://t.me/dogggotoken",
    telegramMemes: "https://t.me/dogggomeme",
    discord: "https://discord.gg/RaAFEdE6bw",
    whitepaper: "https://dogggo.medium.com/dogggopaper-deb09c3398c3"
}
