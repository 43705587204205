import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';
import Web3 from 'web3';

export interface State {
    web3: Web3,
    currentUser: string,
    chainId: number,
    watcher: boolean,
    currentUserNFT: any[],
    NFTSold: string,
    dogggoCollected: number,
    NFTAvailable: string,
    openingTime: number,
    myBalance: number,
    myNFTReserved: number,
    isIMXRegistered: boolean,
    isTokensApproved: boolean
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    state: {
        web3: new Web3(),
        currentUser: "",
        chainId: 0,
        watcher: true,
        currentUserNFT: [],
        NFTSold: "",
        dogggoCollected: 0,
        NFTAvailable: "",
        openingTime: 0,
        myBalance:0,
        myNFTReserved:0,
        isIMXRegistered: false,
        isTokensApproved: false
    },
    mutations: {
        setupWeb3(state: State, provider: any) {
            state.web3 = new Web3(provider);
        },
        setCurrentUser(state: State, user: string) {
            state.currentUser = user;
        },
        setChainId(state: State, id: number) {
            state.chainId = id;
        },
        setCurrentUserNFT(state: State, assetsResult: []) {
            state.currentUserNFT = assetsResult;
        },
        setNFTSold(state: State, nftSold: string) {
            state.NFTSold = nftSold;
        },
        setDogggoCollected(state: State, dogggoCollectedTokens: number) {
            state.dogggoCollected = dogggoCollectedTokens;
        },
        setNFTAvailable(state: State, NFTAvailable: string) {
            state.NFTAvailable = NFTAvailable;
        },
        setOpeningTime(state: State, openingTime: number) {
            state.openingTime = openingTime;
        },
        setMyBalance(state: State, myBalance: number) {
            state.myBalance = myBalance;
        },
        setMyNFTReserved(state: State, myNFTReserved: number) {
            state.myNFTReserved = myNFTReserved;
        },
        setIMXRegistered(state: State, imxRegistered: boolean) {
            state.isIMXRegistered = imxRegistered;
        },
        setApprovetokens(state: State, approveTokens: boolean) {
            state.isTokensApproved = approveTokens;
        }

    },
    getters: {
        currentUser(state) {
          return state.currentUser;
        },
        currentChainId(state) {
            return state.chainId;
        }
      },
})

export function useStore() {
    return baseUseStore(key);
}