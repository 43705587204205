
import Header from "./components/Header.vue";
import NavigationComponent from "./components/NavigationComponent.vue";
import Footer from "./components/Footer.vue";
import Loading from "vue-loading-overlay";

export default {
  name: 'App',
  components: {
    Header,
    NavigationComponent,
    Footer,
    Loading
  },

}

